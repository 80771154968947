import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    Heading6Bold, Heading4, colors, Heading5Bold, fontSize, lineHeight
} from '../CommonComponents/Typography/Typography.styled';
import { InPageAlert} from '@snsw/react-component-library';

const codeFont = 'Menlo, Monaco, "Courier New", Courier, monospace';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    padding-bottom: 56px;
`;

export const HorizontalLine = styled.div`
    height: 2px;
    background-color: #CDD3D6;
    margin: 10px 0px 10px 0px;
`;

export const H4Heading = styled(Heading4)`
    font-family: ${globalFont};
`;

export const H5BoldHeading = styled(Heading5Bold)`
    font-family: ${globalFont};
    color: ${colors.brand.snswPrimaryBlue}
`;

export const H6BoldHeading = styled(Heading6Bold)`
    font-family: ${globalFont};
    color: ${colors.brand.snswPrimaryBlue}
`;

export const BoldFont = styled.span`
    font-weight: ${fontWeight.bold};
`;

export const SemiBoldFont = styled.span`
    font-weight: ${fontWeight.semibold};
`;

export const CodeInContent = styled.span`
    color: ${colors.brand.snswPrimaryBlue};
    background-color: #FFFFFF;
    padding: 2px 4px;
    font-family: ${codeFont};
`;

export const UnorderedList = styled.ul`
    margin: 10px 0rem 0rem 0rem;  
`;

export const ListItem = styled.li`
    margin-bottom: 0rem;  
`;

export const StartingSpace = styled.span`
    padding-left: 1.1rem;  
`;

const TableGrid = styled.div`
    display: grid;
    background-color: #FFFFFF;

    > div {
        font-family: ${globalFont};
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`;

export const Table4Col = styled(TableGrid)`
    grid-template-columns: 122px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

export const Table5Col = styled(TableGrid)`
    grid-template-columns: 122px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

const TableHead = styled.div`
    // cell format
    align-content: center;
    padding-left: 16px;
    border-bottom: 2px solid ${colors.grey.geyser};
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.semibold};
    font-size: ${fontSize.body};
    line-height: 22px;
`;

export const Table4ColHead = styled(TableHead)`
    // cell format
    min-height: 41px;
`;

export const Table5ColHead = styled(TableHead)`
    // cell format
    min-height: 64px;
`;

export const TableCell = styled.div`
    // cell format
    align-content: center;
    border-bottom: 1px solid ${colors.grey.geyser};
    padding-left: 8px;
    padding-right: 8px;
    // font format
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.regular};
    line-height: 18px;
`;

export const Table4ColCellBold = styled(TableCell)`
    // cell format
    padding-left: 16px;
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.bold};
`;

export const Table5ColCellBold = styled(TableCell)`
    // cell format
    padding-left: 16px;
    // font format
    color: ${colors.brand.snswPrimaryBlue};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.body};
    line-height: 18px;
`;

export const TableCellFlex = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    justify-content: space-evenly;
    border-bottom: 1px solid ${colors.grey.geyser};
    
    > div {
        font-size: ${fontSize.body};
        font-weight: ${fontWeight.regular};
        line-height: 18px;
    }
`;

export const CustomerInPageAlert = styled(InPageAlert)`
        margin: 0;
        padding: 0.5rem!important;
        border-left: 0;
        background-color: #FBEEE5;
    path:nth-child(2) {
        fill: #C95000;
        // eslint-disable-next-line max-len
        d: path("M 7.03087 1.09896 C 7.43018 0.300345 8.56984 0.300346 8.96915 1.09896 L 15.8842 13.9291 C
        16.2444 14.6495 15.7205 15.4972 14.9151 15.4972 H 1.08493 C 0.279452 15.4972 
        -0.244432 14.6495 0.11579 13.9291 L 7.03087 1.09896 Z M 8.38334 13.4182 C 8.50452 13.3676 8.61614 
        13.2965 8.71334 13.2082 C 8.75634 13.1607 8.79642 13.1106 8.83334 13.0582 C 8.87118 13.0025 8.90145 
        12.9419 8.92334 12.8782 C 8.95913 12.8227 8.98612 12.762 9.00334 12.6982 C 9.00779 12.6316 9.00779 
        12.5648 9.00334 12.4982 C 9.00176 12.3675 8.97458 12.2384 8.92334 12.1182 C 8.87574 11.9955 8.80438 
        11.8833 8.71334 11.7882 C 8.42578 11.5045 7.99573 11.4216 7.62334 11.5782 C 7.50215 11.6289 7.39054 
        11.6999 7.29334 11.7882 C 7.2023 11.8833 7.13093 11.9955 7.08334 12.1182 C 7.0321 12.2384 7.00492 
        12.3675 7.00334 12.4982 C 6.99889 12.5648 6.99889 12.6316 7.00334 12.6982 C 7.01428 12.7609 7.0345 
        12.8215 7.06334 12.8782 C 7.08523 12.9419 7.11549 13.0025 7.15334 13.0582 L 7.27334 13.2082 C 7.37054 
        13.2965 7.48215 13.3676 7.60334 13.4182 C 7.84538 13.5252 8.1213 13.5252 8.36334 13.4182 H 8.38334 Z 
        M 9 6.5 V 9.5 C 9 10.0523 8.55229 10.5 8 10.5 C 7.44772 10.5 7 10.0523 7 9.5 V 6.5 C 7 5.94772 7.44772 
        5.5 8 5.5 C 8.55229 5.5 9 5.94772 9 6.5 Z")
    }

`;
